import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import customersAPI from '../services/customersAPI'
import ShowInvoices from '../components/ShowInvoices'
import { useParams, useNavigate } from "react-router-dom"

const ClientPage = (props) => {
    var {id} = useParams()
    const navigate = useNavigate()

    const [customer, setCustomer] = useState({
        lastName: "",
        firstName: "",
        email: "",
        company: "",
        invoices: {},
        user:{}
    })

    const fetchClient = async id => {
        try{
            const {firstName, lastName, email, company, invoices, user} = await customersAPI.find(id)
            setCustomer({firstName, lastName, email, company, invoices, user})       

        }catch(error){
            toast.error("Le client n'a pas pu être chargé")
            navigate("/customers", { replace: true });
        }
    }


    useEffect(()=>{
        fetchClient(id)

    },[id])

    const factures = Object.keys(customer.invoices).map(key => {
        return (
            <ShowInvoices key={key} infos={customer.invoices[key]} />
        )
    })

    return ( 
        <>
            <h1>Client page</h1>
            <div><strong>Identity: </strong>{customer.lastName} {customer.firstName}</div>
            <div><strong>User: </strong>{customer.user.lastName} {customer.user.firstName}</div>
            <div className="row">
                {factures}
            </div>


        </>
     );
}

export default ClientPage; 