import { useState } from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Navbar from './components/Navbar';
import CustomersPage from "./pages/CustomersPage";
import HomePage from './pages/HomePage';
import CustomersPageWithPagination from "./pages/CustomersPagesWithPagination";
import InvoicesPage from "./pages/InvoicesPage";
import authAPI from "./services/authAPI";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./components/PrivateRoute"
import AuthContext from './contexts/AuthContext'
import CustomerPage from "./pages/CustomerPage";
import InvoicePage from "./pages/InvoicePage";
import RegisterPage from "./pages/RegisterPage";
import ClientPage from './pages/ClientPage.jsx'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

authAPI.setup()

function App() {
  const [isAuthenticated, setIsAuthenticate] = useState(authAPI.isAuthenticated())

   // on donne les informations à la form de notre context
   const contextValue = {
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: setIsAuthenticate
}

  return (
    <AuthContext.Provider value={contextValue}>
      <Router>
        <Navbar/>
        <main className="container pt-5">
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/customerspage" element={<CustomersPageWithPagination />} />
                <Route path="/client/:id" element={
                  <PrivateRoute>
                    <ClientPage />
                  </PrivateRoute>
                } />
                <Route path="/customers/:id" element={
                  <PrivateRoute>
                    <CustomerPage />
                  </PrivateRoute>
                } />
                <Route path="/customers" element={
                  <PrivateRoute>
                    <CustomersPage />
                  </PrivateRoute>
                } />
                <Route path="/invoices/:id" element={
                  <PrivateRoute>
                    <InvoicePage />
                  </PrivateRoute>
                } />
                <Route path="/invoices" element={
                  <PrivateRoute>
                    <InvoicesPage />
                  </PrivateRoute>
                } />
                <Route path="/" element={<HomePage />} />
            </Routes>
        </main>
      </Router>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
    </AuthContext.Provider>
  );
}

export default App;
