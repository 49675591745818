import { NavLink, useNavigate  } from "react-router-dom";
import authAPI from "../services/authAPI";
import { useContext } from "react";
import AuthContext from '../contexts/AuthContext'
import { toast } from 'react-toastify'

const Navbar = (props) => {
    const navigate = useNavigate()
    const {isAuthenticated, setIsAuthenticated } = useContext(AuthContext)

    const handleLogout = () => {
        authAPI.logout()
        setIsAuthenticated(false)
        toast.info("Vous êtes déconnecté")
        navigate("/login", { replace: true });
    }

    return ( 
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-2">
            <NavLink className="navbar-brand" to="/">Api-Platform React</NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarColor02">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/customers">Clients</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/invoices">Factures</NavLink>
                    </li>
                </ul>
                <ul className="navbar-nav ms-auto">
                    {(!isAuthenticated) ? (
                            <>
                                <li className="nav-item">
                                    <NavLink to="/register" className="nav-link">Inscription</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/login" className="btn btn-success">Connexion</NavLink>
                                </li>
                            </>
                        ) : (

                            <li className="nav-item">
                                <button onClick={handleLogout} className="btn btn-danger">Déconnexion</button>
                            </li>

                    )}
                </ul>
            </div>
        </nav> 
     );
}
 
export default Navbar;